/* 기본 폰트 */
@font-face {
  font-family: "MaruBuri";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.ttf)
      format("truetype");
}

/* 나눔스퀘어 네오 폰트 */
@font-face {
  font-family: "NanumSquareNeoLight";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-aLt.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-aLt.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-aLt.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeo";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeoBold";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeoExtraBold";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeoHeavy";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.ttf)
      format("truetype");
}

/* 북명조 */
@font-face {
  font-family: "BookkMyungjo-Bd";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/BookkMyungjo-Bd.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

/* 기타 폰트 */
@font-face {
  font-family: "YoonChildfundkoreaManSeh";
  src: url("https://example.com/yoonchildfundkoreamanseh.woff");
}

@font-face {
  font-family: "BMDOHYEON";
  src: url("https://example.com/bmdohyeon.woff");
}

@font-face {
  font-family: "hanna";
  src: url("https://example.com/hanna.woff");
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Bungee+Tint&family=Gloria+Hallelujah&display=swap");

.gloria-hallelujah-regular {
  font-family: "Gloria Hallelujah", serif;
  font-weight: 400;
  font-style: normal;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 60px;
  background-color: #ffeaea;
  box-sizing: border-box;
  font-family: "NanumSquareNeo" !important;
  font-weight: bold;
}

.homeHeader {
  background-color: white;
  box-shadow: none;
}

.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.title {
  font-size: 28px;
  margin-left: 20px;
  letter-spacing: 1.5px;
  font-weight: bold;
  font-family: "Gloria Hallelujah", cursive;
  color: #de2a2a;
}

.nav {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-right: 50px;
}

.navLink {
  text-decoration: none;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.navLink:hover {
  color: #e40046;
}

.profileSection {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: -20px;
  position: relative;
}

.logoutLink {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  font-size: 18px;
  color: #e40046;
  cursor: pointer;
  padding: 20px;
  border: none;
  background-color: inherit;
  display: none;
  transition: transform 0.1s ease;
}

.profileSection:hover .logoutLink {
  display: block;
}

/*→ 프로필 섹션에 마우스를 올렸을 때, logoutLink 버튼을 제외한 나머지 모든 자식 요소들을 숨긴다 */
.profileSection:hover > *:not(.logoutLink) {
  visibility: hidden;
}

.profileImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.nickname {
  font-weight: bold;
  color: #070707;
}
