.sectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    white 60%,
    rgba(255, 235, 235, 0.997) 100%
  );
}

.sectionImage {
  height: 60%;
  object-fit: cover;
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.8s ease-in-out;
}

.sectionImage:hover {
  transform: scale(1.1) !important;
  transition: transform 0.8s ease-in-out;
}
