.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px;
}

.title {
  font-size: 32px;
  font-family: "NanumSquareNeoLight";
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.section {
  margin-bottom: 50px;
}

.sectionTitle {
  font-size: 24px;
  font-family: "NanumSquareNeoLight";
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}

.teamContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.memberCard {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 10px;
  margin-left: 10px;
}

.memberImage {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.memberName {
  font-size: 20px;
  font-family: "NanumSquareNeo";
  margin-bottom: 1px;
  color: #333;
}

.memberIntroduction {
  font-size: 15px;
  font-family: "NanumSquareNeo";
  margin-bottom: -5px;
  color: #000000;
}

.memberDescription {
  font-size: 15px;
  font-family: "NanumSquareNeoLight";
  margin-bottom: -1px;
  color: #000000;
}

.memberTagline {
  font-size: 14px;
  font-family: "NanumSquareNeoLight";
  color: #ff4040;
}
