.container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  font-family: "NanumSquareNeo", sans-serif;
}

.content {
  display: flex;
  align-items: flex-start;
}

.passage {
  position: relative;
  flex: 3;
  padding-right: 50px;
  font-family: "MaruBuri" !important;
}
.questions {
  flex: 2;
  padding-left: 10px;
  background-color: rgb(251, 253, 255);
  display: flex;
}

.container::before {
  content: "";
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 60%;
  width: 1.5px;
  background-color: #000000;
}

.BTNs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -10px;
  margin-top: -20px;
  margin-bottom: -10px;
}

.WordBtn,
.showPassageButton {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  margin-left: 10px;
}
.resetButton {
  font-family: "MaruBuri" !important;
  padding: 15px;
  border-radius: 50px;
  margin-left: 10px;
  font-weight: bold !important;
  margin-right: -15px;
  border: none;
  cursor: pointer;
  background-color: #ffeaea;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.resetButton:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.showPassageButton:hover {
  background-color: #c8c0fd;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.WordBtn:hover {
  background-color: #fad4d0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.CloseFullPassageBTN {
  position: absolute;
  top: 10px;
  right: 80px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.CloseFullPassageBTN:hover {
  background-color: #fad4d0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.Jimoon {
  padding-top: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  line-height: 1.6;
  text-indent: 20px;
  font-family: "MaruBuri" !important;
  font-size: 18px;
}

.option:hover {
  color: rgb(255, 165, 20);
  cursor: pointer;
}

.questions ol {
  padding-left: 30px;
  font-size: 17.5px;
  line-height: 1.6;
}

.submitButton {
  display: block;
  width: 30%;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: auto;
  margin-right: 5%;
  margin-top: 20px;
}

.submitButton:hover {
  background-color: #ff523f;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.radioBtn {
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.radioBtn:checked {
  background-color: rgb(255, 165, 20);
  border: none;
}

.checked {
  margin-left: 8px;
}

.notice {
  margin-bottom: 10px;
  color: rgb(94, 94, 94);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: 80%;
  min-width: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  width: auto;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: auto;
}

.popupTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.popupWarning {
  font-size: 14px;
  color: #ff0000;
  margin-bottom: 20px;
}

.popupButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
}

.cancelButton,
.confirmButton {
  padding: 8px 16px;
  margin-top: -30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #333;
}

.confirmButton {
  background-color: #eb4a44;
  color: white;
}
.fullPassage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  overflow: auto;
  z-index: 1000;
}

.FullJimoon {
  justify-content: center;
  margin-left: 5%;
  margin-right: 8%;
  line-height: 1.6;
  text-indent: 20px;
  margin-top: 50px;
  font-family: "MaruBuri" !important;
}

.modalContent {
  background-color: #fffde7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 60%;
  min-width: 300px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  position: absolute;
  top: 10%;
  right: 10%;
  overflow: auto;
}

.modalHeader {
  position: sticky;
  background-color: #fffde7;
  padding-top: 5px;
  padding-bottom: 25px;
  top: 0;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  border-bottom: 2px solid black;
}

.wordList {
  list-style: none;
  padding: 10px;
  margin: 0;
  flex: 1;
  overflow-y: auto;
}

.wordItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.deleteButton {
  margin-left: 8px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.closeButton {
  background-color: #fff;
  color: #ff5f5f;
  border: 2px solid #ff5f5f;
  width: 100px;
  height: 30px;
  font-size: 1em;
  border-radius: 10px;
  cursor: pointer;
  align-self: center;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.closeButton:hover {
  background-color: #ffebeb;
}

.highlight {
  background-color: rgb(253, 252, 170);
}

.Timer {
  font-size: 12px;
  margin-right: 30px;
  position: absolute;
  top: -10px;
  display: flex;
  align-items: center;
  right: 30px;
}

.timerBTN {
  position: absolute;
  top: 10px;
  right: -20px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffe0e0;
  margin-left: 10px;
  font-weight: bold;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
