.SignUp {
  font-family: "NanumSquareNeo";
  color: #ff6363;
  font-size: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: -15px;
}

.signupContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
  min-height: 100vh;
  padding: 2rem;
  justify-content: center;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}

.profileImageWrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profilePlaceholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 50%;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.labelStyle {
  font-size: 16px;
  color: black;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.labelStyle:hover {
  color: red;
  text-decoration: underline;
}

/* 회원가입 폼 오른쪽 배치 */
.signupForm {
  flex: 1;
  max-width: 600px;
  padding: 2rem;
  border-radius: 16px;
  background-color: #fff;
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.formGroup label {
  font-family: "NanumSquareNeo";
  width: 150px;
  margin-right: 1rem;
  color: #101010;
}

.formGroup input,
.formGroup select {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.formGroup input:focus,
.formGroup select:focus {
  outline: none;
  border-color: #ff6b6b;
}

.submitButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #ff6b6b;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 1.1rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #ff5252;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.modalContent {
  background: white;
  width: 90%;
  max-width: 700px;
  max-height: 80vh;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: slideUp 0.3s ease-in-out;
}

.modalBody {
  max-height: 60vh;
  overflow-y: auto;
  padding: 10px;
  text-align: left;
}

.modalContent h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.modalContent p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  word-break: keep-all; /* 단어 단위 줄바꿈 */
}

.modalFooter {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
}

.modalContent button {
  background-color: #ff0303;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  flex: 1;
  margin: 0 5px;
}

.modalContent button:hover {
  background-color: #fd9a9a;
}

.modalContent .cancelButton {
  background-color: #ddd;
  color: #333;
}

.modalContent .cancelButton:hover {
  background-color: #bbb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 모바일 반응형 */
@media (max-width: 480px) {
  .modalContent {
    width: 95%;
    max-width: 95%;
  }

  .modalBody {
    max-height: 50vh;
  }

  .modalContent h3 {
    font-size: 1.2rem;
  }

  .modalContent p {
    font-size: 0.9rem;
  }

  .modalContent button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
