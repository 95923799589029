.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profileBox {
  text-align: center;
}

.profileCircle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid green;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageOverlay {
  position: absolute;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.nickname {
  font-size: 22px;
  font-weight: 500;
  margin-top: 15px;
}

.redText {
  color: red;
  margin-top: 8px;
  cursor: pointer;
  font-size: 16px;
}

.noticeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.noticeText {
  font-size: 20px;
  font-weight: 500;
  color: #555;
}
