.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 30px;
  padding-top: 20px;
  border-radius: 8px;
  width: 300px;
  margin-top: 60px;
  margin-right: 50px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  font-family: "NanumSquareNeoLight";
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "NanumSquareNeoLight";
}

.loginButton {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
  font-family: "NanumSquareNeoLight";
  background-color: #d31717;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: #ffc2c2;
}

.signup {
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.signup:hover {
  text-decoration: underline;
}
