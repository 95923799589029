.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(255, 235, 235, 0.997) 60%,
    white 100%
  );

  padding: 40px 20px;
}

.card {
  width: 70%;
  background-color: #ffdde365;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.9s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: left;
  font-size: 24px;
  font-family: "NanumSquareNeoBold";
  margin-bottom: 16px;
  color: #222;
  width: 100%;
}

.accordion {
  width: 100%;
}

.item {
  margin-bottom: 8px;
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease-in-out;
}

.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 18px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: #ffffff;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.content {
  padding: 14px 18px;
  background: #ffffff;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 550;
  color: #0f0f0f;
  border-top: 1px solid #e0e0e0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  font-family: "NanumSquareNeoLight";
  overflow: hidden;
}

.accordion [data-state="open"] .content {
  max-height: 300px;
  opacity: 1;
}

.startButton {
  display: block;
  margin-top: 50px;
  margin-bottom: 30px;
  background-color: #ff5f5f;
  color: white;
  width: 620px;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "NanumSquareNeoBold";
  text-align: center;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.startButton:hover {
  background-color: #e04e4e;
  transform: scale(1.05);
}
