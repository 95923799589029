.container {
  max-width: 90%;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: "NanumSquareNeo";
}

.header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  font-family: "NanumSquareNeoBold" !important;
  font-weight: bolder;
}

.passage {
  width: 96%;
  margin: 10px auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.6;
  background-color: #fafafa;
  color: #171717;
  font-family: "MaruBuri";
}

.splitContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.answers {
  width: 48%;
  padding: 20px;
}

.vocabulary {
  width: 48%;
  padding: 20px;
}

.vocabulary h2 {
  color: #ff5f5f;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "MaruBuri";
}

.wordList {
  padding: 0;
  list-style: decimal;
  text-align: left;
  margin-left: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.wordItem {
  color: #000000;
  margin-bottom: 15px;
}

.word {
  color: #002f5d;
}

.questionList {
  padding: 0;
  list-style: none;
  text-align: left;
}

.questionItem {
  margin-bottom: 50px;
}

.questionItem p {
  font-weight: bold;
}

.choices {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.choice {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  transition: all 0.3s;
}

.correctChoice {
  border: 2px solid #ff2121;
  color: #ff2121;
  background-color: #ffdbdb;
  font-weight: bold;
}

.correctSelected {
  border: 2.5px solid #ff0505;
  background-color: #bfceff;
  color: #000000;
  font-weight: bold;
}

.incorrectSelected {
  border: 2px solid #ddd;
  background-color: #becdff;
  color: #333333;
  font-weight: bold;
}

.explanation {
  margin-top: 15px;
  font-size: 15px;
  color: #ff2121;

  text-indent: 10px;
  font-weight: bold;
}

.explanationText {
  line-height: 1.6;
  font-weight: normal !important;
  color: black !important;
}

.startButton {
  display: block;
  margin: 40px auto 0;
  background-color: #ff5f5f;
  color: white;
  width: 90%;
  border: none;
  padding: 15px 30px;
  font-size: 1em;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

a {
  text-decoration: none;
}

.startButton:hover {
  background-color: #e53e3e;
  box-shadow: 0 4px 15px rgba(229, 62, 62, 0.5);
}
