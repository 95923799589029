.page {
  background-color: rgba(255, 235, 235, 0.997);
}

.wrapper {
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.sectionContainer {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
}

.sectionImage {
  width: 90%;
  max-width: 950px;
  height: auto;
  object-fit: contain;
  box-shadow: 5px 5px 15px rgba(255, 126, 126, 0.222);
  border-radius: 30px;
  transition: opacity 0.5s ease-in-out;
}
.bottomNavButtons {
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: flex;
  gap: 10px;
}

.navButton {
  background: white;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background: rgba(0, 0, 0, 0.8);
  color: white;
}
